// import { useState } from 'react';
// import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { SuccessCard } from '../../components/SuccessCard';
import { Homepage } from '../homepage/Homepage';
// import { OAUTH_COMPLETE_EVENT, OAUTH_MESSAGE_SOURCE } from '../../components/OauthWindow';

// function paramsToObject(entries) {
//   const result = {};
//   for (const [key, value] of entries) {
//     // each 'entry' is a [key, value] tupple
//     result[key] = value;
//   }
//   return result;
// }

function OAuthPage(props) {
  const location = useLocation();
  // const navigate = useNavigate();
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const success = searchParams.get('success');
  const token = searchParams.get('token');

  // setSuccess();
  // setToken(searchParams.get('token'));
  // useEffect(() => {
  //   // hide drawer
  //   // searchParams.set('embed', '' + true);
  //   const url = location.pathname + '?' + searchParams.toString();
  //   navigate(url);
  //   // end hide drawer
  //   // const reload = Boolean(searchParams.get('reload'));
  //   // if (!window?.opener) {
  //   //   console.log('Window.opener null');
  //   //   navigate('/');
  //   //   return;
  //   // } else if (reload) {
  //   //   console.log('Window.opener reloading');
  //   //   window.opener.location.reload();
  //   // } else {
  //   //   console.log('Window.opener send message');
  //   //   const params = paramsToObject(searchParams);
  //   //   window?.opener?.postMessage(
  //   //     {
  //   //       event: OAUTH_COMPLETE_EVENT,
  //   //       source: OAUTH_MESSAGE_SOURCE,
  //   //       payload: params,
  //   //     },
  //   //     '*'
  //   //   );
  //   // }
  //   setTimeout(() => {
  //     window.close();
  //   }, 1000);
  // }, []);

  if (token !== null)
    return (
      <main>
        <SuccessCard token={token} />
        <Homepage disable={true} />
      </main>
    );
  return (
    <main className="">
      {success === 'false' ? (
        <>
          <h1>Authentication Failed</h1>
        </>
      ) : (
        <>
          <h1>Authentication in progress</h1>
          <div className="">Redirecting...</div>
        </>
      )}
    </main>
  );
}

export default OAuthPage;
